// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Categories_main__iBczV{
    padding: 50px 30px;
    background-color: rgb(247, 245, 242);
    min-height: 100vh;
    font-family: "Inter", sans-serif;
}

.Categories_main__iBczV h3{
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.167;
    color: rgb(18, 25, 38);
}

.Categories_card__DE8Td{
    background-color: rgb(255, 255, 255);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border: 1px solid rgb(227, 232, 239);
    background-image: none;
    /* height: 100%; */
    padding: 16px;
    text-align: left;
    color: black;
    border-radius: 16px;
    cursor: pointer;
    width: 100%;
    -webkit-user-select: none;
            user-select: none;
    min-height: 100px;
}

.Categories_card__DE8Td h5{
    white-space: nowrap;
    color: rgb(0, 0, 0);
    font-size: 14px;
    font-weight: 700;
    margin: 0px;
}

.Categories_card__DE8Td p{
    font-size: 14px;
    color: rgb(107, 114, 128);
    margin: 0px;
    font-weight: 300;
}`, "",{"version":3,"sources":["webpack://./src/pages/Categories/Categories.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,oCAAoC;IACpC,iBAAiB;IACjB,gCAAgC;AACpC;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,oCAAoC;IACpC,6DAA6D;IAC7D,oCAAoC;IACpC,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,gBAAgB;IAChB,YAAY;IACZ,mBAAmB;IACnB,eAAe;IACf,WAAW;IACX,yBAAiB;YAAjB,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,eAAe;IACf,yBAAyB;IACzB,WAAW;IACX,gBAAgB;AACpB","sourcesContent":[".main{\n    padding: 50px 30px;\n    background-color: rgb(247, 245, 242);\n    min-height: 100vh;\n    font-family: \"Inter\", sans-serif;\n}\n\n.main h3{\n    font-weight: 600;\n    font-size: 1.25rem;\n    line-height: 1.167;\n    color: rgb(18, 25, 38);\n}\n\n.card{\n    background-color: rgb(255, 255, 255);\n    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;\n    border: 1px solid rgb(227, 232, 239);\n    background-image: none;\n    /* height: 100%; */\n    padding: 16px;\n    text-align: left;\n    color: black;\n    border-radius: 16px;\n    cursor: pointer;\n    width: 100%;\n    user-select: none;\n    min-height: 100px;\n}\n\n.card h5{\n    white-space: nowrap;\n    color: rgb(0, 0, 0);\n    font-size: 14px;\n    font-weight: 700;\n    margin: 0px;\n}\n\n.card p{\n    font-size: 14px;\n    color: rgb(107, 114, 128);\n    margin: 0px;\n    font-weight: 300;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `Categories_main__iBczV`,
	"card": `Categories_card__DE8Td`
};
export default ___CSS_LOADER_EXPORT___;
