import { useNavigate } from 'react-router-dom';
import classes from './Categories.module.css'
import { dataList } from '../../data';



const Categories = () => {
    let navigate = useNavigate();
    return(
        <div className={`${classes.main}`}>
            <h3>DBE Assistant</h3>
            <div className='row mt-4'>
                {dataList.map((item, i) => (<div className='col-6 mb-4' key={i}>
                    <div className={`${classes.card} `} onClick={() => navigate({pathname: `/category/${item.id}`})}>
                        <h5>{item.title}</h5>
                        <p>{item.desc}</p>
                    </div>
                </div>))}
            </div>
        </div>
    )
}

export default Categories