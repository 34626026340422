import { BrowserRouter, Route, Routes } from "react-router-dom";
import Categories from "./pages/Categories/Categories";
import Category from "./pages/Category/Category";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
          <Routes>
              <Route path="/"  element={<Categories />} />
              <Route path="/category/:id" element={<Category />}/>
          </Routes>
       </BrowserRouter>       
    </div>
  );
}

export default App;
