export const dataList = [
    {
        title: "Exams",
        desc: "Exams are formal assessments used to evaluate a person’s knowledge, skills, or abilities in a specific subject or field.",
        id: "c1"
    },
    {
        title: "Annual Calendar",
        desc: "An annual calendar is a tool used to outline and organize events, appointments, holidays, and important dates for the entire year.",
       id: "c2"
    },
    {
        title: "Learners",
        desc: "Learners are individuals who are in the process of acquiring knowledge, skills, or competencies through study, experience, or instruction.",
       id: "c3"
    },
    {
        title: "General Information",
        desc: "General information refers to broad, non-specialized knowledge that encompasses a wide range of topics and facts.",
        id: "c4"
    }
]