import { useNavigate, useParams } from 'react-router-dom';
import { dataList } from '../../data'
import classes from './Category.module.css'
import { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FaArrowLeft } from "react-icons/fa";


const Category = () => {
    const { id } = useParams();
    const [item, setItem] = useState(null);
    const [question, setQuestion] = useState("")
    const [answers, setAnswers] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [sessionId, setSessionId] = useState(null)

    const buttonRef = useRef(null);

    const navigate = useNavigate();

    useEffect(() => {
        if (answers) {
        buttonRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [answers]);


    useEffect(() => {
        const foundItem = dataList.find(item => item.id === id);
        setItem(foundItem);
      }, [id]);


      useEffect(() => {
        setSessionId(uuidv4());
      }, []);

    //   console.log("SESSION ID", sessionId)


    //   useEffect(() => {
	// 	createChat({
	// 		webhookUrl: 'https://n8n.thutomdm.com/webhook/792d3290-b52a-486c-85a8-d736e7807c0e/chat'
	// 	});
	// }, []);


      

      const generateHandler = async () => {
            if(!question){
                return
            }

            setIsLoading(true)
            const sendRequest = async () => {
            const response = await fetch(`https://n8n.thutomdm.com/webhook/55278186-7b9c-4f81-92a7-c132a5df95c3`, {
                method: "POST",
                headers: {
                "Content-Type": "application/json",
                },
                body: JSON.stringify({ 
                    sessionId,
                    action: "sendMessage",
                    chatInput: question
                })
            });

            if (!response.ok) {
                const result = await response.json();

                console.log("Error", result);
                setIsLoading(false)
            }

            const result = await response.json();

            console.log("Reply from plays data", result);

            setQuestion("")
            setAnswers((prevAnswer) => [...prevAnswer, {question, answer: result[0].output}])
            setIsLoading(false)
            return result;
            };

            try {
            await sendRequest();
            
            } catch (error) {
                setIsLoading(false)
            console.log(error);
            }
       
      }


      

    const goBack = () => {
        navigate(-1);
    };

    return(
        <div className={`${classes.main} row`}>
            <div className='col-md-4 col-12'><div className={`${classes.backButton} d-flex align-items-center gap-3`} onClick={goBack} role='button'><FaArrowLeft/>Back</div></div>
            <div className='col-md-4 col-12'>
                <div className={`${classes.card}`}>
                <h2>{item?.title}</h2>
                <h4>{item?.desc}</h4>
                {answers && <div className={classes.answerContainer}>
                {answers.map((item, i) => (<div key={i} className='mb-4'>
                    <div className='d-flex  justify-content-end align-items-center'>
                        <div className={`${classes.questionConatiner} mb-3`}>{item.question}</div>
                    </div>
                    <p>{item.answer}</p>
                    </div>))}
                <div ref={buttonRef}></div>
                </div>}
                <textarea className='mt-4' onChange={(e) => setQuestion(e.target.value)} value={question}/>
                {!isLoading && <button className='mt-4' onClick={generateHandler}>Generate</button>}
                {isLoading && (
                    <div className='d-flex  justify-content-center align-items-center mt-4'>
                        <div className="spinner-border" role="status">
                        </div>
                    </div>    
                )}
                </div>
            </div>
        </div>
    )
}

export default Category